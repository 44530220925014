import { commonEnvironment } from './common.environment';

export const environment = {
  ...commonEnvironment,
  production: true,
  showCreatePdfButton: false,
  preventFormUnload: true,
  envName: 'prod',
  serverApiUrl: 'https://planning.connect.wwag.ch',
  authServerUrl: 'tbd',
};
